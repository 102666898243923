import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import firebase from '../Firebase';
import OnboardingLayout from '../components/OnboardingLayout';
import { AuthContext } from '../contexts/Auth';
import { track } from '../utils/analytics';
import { emailIsValid } from '../utils/validator';

const HAVE_ACCOUNT = {
  marginTop: 24,
  marginBottom: 8,
};

const FORM = {
  width: '100%',
};

const ERROR_MESSAGE = {
  color: 'red',
  fontSize: 14,
  textAlign: 'start',
};

const ResetPassword = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const [email, setEmail] = useState('');

  const [errorEmail, setErrorEmail] = useState(null);

  const [error, setError] = useState(null);
  const [resetLinkSent, setResetLinkSent] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let isValid = true;
    if (!emailIsValid(email)) {
      setErrorEmail('Email is not valid');
      isValid = false;
    }
    return isValid;
  };

  const onResetPassword = async () => {
    setError(null);
    if (checkValidation()) {
      setLoading(true);
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent!
          // ..
          setLoading(false);
          setResetLinkSent(true);
        })
        .catch((error) => {
          const errorMessage = error.message;
          setLoading(false);
          setError(errorMessage);
          // ..
        });
    }
    track('reset_password_button_click');
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/home');
    }
  }, [isAuthenticated, navigate]);

  return (
    <OnboardingLayout>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Reset Password
        </Typography>
        <div></div>
        {resetLinkSent ? (
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: 'gray.700' }}>
            An email with instructions to reset your password was sent to you.
          </Typography>
        ) : (
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: 'gray.700' }}>
            HealthStory will send you a letter to the provided email below if a
            profile exists with that email address.
          </Typography>
        )}
        <div></div>
        <div></div>
        {!resetLinkSent && (
          <TextField
            label="Email"
            fullWidth
            type={'email'}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorEmail(null);
            }}
            error={errorEmail !== null}
            helperText={errorEmail}
          />
        )}
        {!resetLinkSent && (
          <LoadingButton
            loading={loading}
            onClick={() => onResetPassword()}
            variant="contained"
            size="large"
          >
            Reset Password
          </LoadingButton>
        )}
        {resetLinkSent && (
          <LoadingButton
            onClick={() => navigate('/login')}
            variant="contained"
            size="large"
          >
            Back to Login
          </LoadingButton>
        )}
        {error && <Typography sx={ERROR_MESSAGE}>{error}</Typography>}
        {!resetLinkSent && (
          <Stack
            style={HAVE_ACCOUNT}
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={0}
          >
            <Typography sx={{ fontSize: 16 }}>Back to</Typography>
            <Button onClick={() => navigate('/login')} size="medium">
              login
            </Button>
          </Stack>
        )}
      </Stack>
    </OnboardingLayout>
  );
};

export default ResetPassword;
