import {
  AddRounded,
  ArrowBackIos,
  CalendarTodayOutlined,
  CameraAltOutlined,
  CancelOutlined,
  EditOutlined,
  Mic,
  SellOutlined,
  SentimentSatisfiedOutlined,
  Stop,
} from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import dayjs from 'dayjs';
import EmojiPicker, { SkinTonePickerLocation } from 'emoji-picker-react';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { Image, ImageGroup } from 'react-fullscreen-image';
import { ReactMic } from 'react-mic';
import { v4 as uuidv4 } from 'uuid';
import firebase from '../Firebase';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';
import AddCustomTagDialog from './AddCustomTagDialog';
import CustomizedDialogs from './Dialog';
import UploadProgressDialog from './UploadProgressDialog';

// eslint-disable-next-line react/prop-types
export default function AddOrEditEventDialog({
  open,
  setOpen,
  event = null,
  postJournal,
}) {
  const { fetchEvents, openRecordScreen, setOpenRecordScreen } =
    React.useContext(EventsContext);
  const { currentUser } = React.useContext(AuthContext);
  const { currentProfile, fetchCustomEvents, customEvents, organization } =
    React.useContext(ProfileContext);
  const [emojis, setEmojis] = React.useState(['😞', '🙁', '😕', '🙂', '😀']);
  const [selectedEvents, setSelectedEvents] = React.useState([]);
  const [selectedEmoji, setSelectedEmoji] = React.useState(null);
  const [description, setDescription] = React.useState('');
  const [openEmojiDialog, setOpenEmojiDialog] = React.useState(false);
  const [openNewTagDialog, setOpenNewTagDialog] = React.useState(false);
  const [datetime, setDatetime] = React.useState(dayjs(moment().toString()));
  const [loadingAddingEvent, setLoadingAddingEvent] = React.useState(false);
  const [loadingDeletingEvent, setLoadingDeletingEvent] = React.useState(false);
  const [customTag, setCustomTag] = React.useState('');
  const [openUploadProgressDialog, setOpenUploadProgressDialog] =
    React.useState(null);
  const [progress, setProgress] = React.useState(0);

  const [file, setFile] = React.useState(null);
  const [fileData, setFileData] = React.useState(null);

  const [imageUrl, setImageUrl] = React.useState(null);

  const [opened, setOpened] = React.useState(true);

  const [loadingSpeechToText, setLoadingSpeechToText] = React.useState(false);

  const [recordStatus, setRecordStatus] = React.useState('play');

  const isDesktop = useResponsive('up', 'md');

  const dateTimePickerRef = React.useRef();

  const fileInputRef = React.useRef(null);

  const handleFileSelect = () => {
    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  const [isRecording, setIsRecording] = React.useState(false);
  const [audioBlob, setAudioBlob] = React.useState(null);

  const [dots, setDots] = React.useState(1);

  React.useEffect(() => {
    if (isRecording || loadingSpeechToText) {
      const intervalId = setInterval(() => {
        setDots((prevDots) => (prevDots % 3) + 1);
      }, 300);

      return () => clearInterval(intervalId);
    }
  }, [isRecording, loadingSpeechToText]);

  const startRecording = () => {
    setDescription('');
    setIsRecording(true);
  };

  const stopRecording = () => {
    setLoadingSpeechToText(true);
    setIsRecording(false);
  };

  React.useEffect(() => {
    setOpened(false);
  }, []);

  // React.useEffect(() => {
  //   if (audioBlob) {
  //     convertAudioToText();
  //   }
  // }, [audioBlob]);

  const onData = (recordedBlob) => {
    // Handle recording data if needed
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blob);
    convertAudioToText(recordedBlob.blob);
  };

  const convertAudioToText = async (audioBlob) => {
    if (!audioBlob) {
      console.error('No audio to convert.');
      return;
    }
    setDescription('');
    const arrayBuffer = await audioBlob.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const formData = new FormData();
    formData.append(
      'audio',
      new Blob([uint8Array], { type: 'audio/wav' }),
      'recording.wav',
    );
    try {
      const response = await axios({
        method: 'post',
        url: 'https://knowrare-platform-e2cc9.ew.r.appspot.com/api/speechtotext',
        // url: 'http://localhost:8080/api/speechtotext',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setDescription(response.data.text);
      setOpenRecordScreen(false);
      setTimeout(() => {
        setLoadingSpeechToText(false);
      }, 500);
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
  };

  React.useEffect(() => {
    if (open) {
      if (!event) {
        setOpenRecordScreen(true);
      }
    }
  }, [open, event]);

  React.useEffect(() => {
    if (openNewTagDialog === false) {
      fetchCustomEvents();
    }
  }, [openNewTagDialog]);

  React.useEffect(() => {
    if (file) {
      PreviewImage();
      UploadImage();
    }
  }, [file]);

  const PreviewImage = () => {
    const oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      setFileData(oFREvent.target.result);
    };
  };

  const handleFile = (files) => {
    if (files.length === 0) return;
    const f = files[0];
    setFile(f);
  };

  React.useEffect(() => {
    if (event) {
      setSelectedEmoji(event.data().emoji);
      setDescription(event.data().description);
      setSelectedEvents(event.data().events);
      setDatetime(
        dayjs(moment(event.data().submittedAt.seconds * 1000).toString()),
      );
      setImageUrl(event.data().imageUrl || null);
      if (!emojis.includes(event.data().emoji)) {
        setEmojis([event.data().emoji, ...emojis]);
      }
    }
  }, [event]);

  React.useEffect(() => {
    if (open === false) {
      reset();
    } else {
      if (!event) {
        track('journal-started');
      }
    }
  }, [open]);

  const onEventClick = (id) => {
    const array = selectedEvents;
    if (array.includes(id)) {
      const index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    setSelectedEvents([...array]);
  };

  const addEvent = async () => {
    setLoadingAddingEvent(true);
    const data = {
      createdBy: currentUser.uid,
      userId: currentUser.uid,
      profileId: currentProfile?.id,
      emoji: selectedEmoji,
      format: 'new',
      submittedAt: firebase.firestore.Timestamp.fromDate(datetime.toDate()),
      imageUrl,
      events: selectedEvents,
      description,
    };
    if (event) {
      await firebase
        .firestore()
        .collection('events')
        .doc(event.id)
        .update(data);
    } else {
      track('journal-submission', data);
      await firebase.firestore().collection('events').add(data);
      reset(true);
      return;
    }
    reset(false);
  };

  const deleteEvent = async () => {
    setLoadingDeletingEvent(true);
    await firebase.firestore().collection('events').doc(event.id).delete();
    setLoadingDeletingEvent(false);
    reset();
  };

  const reset = async (submitted = false) => {
    setDescription('');
    setSelectedEvents([]);
    setSelectedEmoji(null);
    setOpen(false);
    await fetchEvents(currentProfile?.id);
    setLoadingAddingEvent(false);
    if (submitted) {
      postJournal();
    }
  };

  const UploadImage = async () => {
    setProgress(0);
    setOpenUploadProgressDialog(true);
    const storageRef = firebase.storage().ref('/events/images/');
    const uploadTask = storageRef.child(uuidv4()).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const _progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(_progress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setOpenUploadProgressDialog(false);
          setImageUrl(downloadURL);
        });
      },
    );
  };

  const [analyzingText, setAnalyzingText] = React.useState(false);

  const convertTagNamesToIds = (names) => {
    const ids = [];
    for (let index = 0; index < [...EVENTS, ...customEvents].length; index++) {
      const event = [...EVENTS, ...customEvents][index];
      if (names.includes(event.name)) {
        ids.push(event.id);
      }
    }
    return ids;
  };

  const analyzeText = async () => {
    setAnalyzingText(true);
    // const res = await axios.post(
    //   'https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/analyzeSpeechToText',
    //   {
    //     text: description,
    //     tags: [...EVENTS, ...customEvents].map((e) => e.name),
    //   },
    // );
    // if (res.data?.choices?.length > 0) {
    //   const data = JSON.parse(res.data.choices[0].message.content);
    //   console.log('data', data);
    //   // setSelectedEmoji(data.emoji);
    //   // setSelectedEvents(convertTagNamesToIds(data.tags));
    // }
    setOpenRecordScreen(false);
    setAnalyzingText(false);
  };

  const descriptionRef = React.useRef(null);

  const getEvents = () => {
    if (organization === 'Story Catch') {
      const removeTypes = [
        'procedures',
        'hospitalVisit',
        'doctorVisit',
        'symptoms',
      ];
      return EVENTS.filter((e) => !removeTypes.includes(e.type));
    } else {
      const removeTypes = ['appointments', 'triggers', 'glimmers', 'myCheckIn'];
      return EVENTS.filter((e) => !removeTypes.includes(e.type));
    }
  };

  return (
    <>
      <UploadProgressDialog
        open={openUploadProgressDialog}
        setOpen={setOpenUploadProgressDialog}
        progress={progress}
      />{' '}
      <CustomizedDialogs
        title={'What is your mood?'}
        open={openEmojiDialog}
        setOpen={setOpenEmojiDialog}
      >
        <EmojiPicker
          width={'100%'}
          autoFocusSearch={false}
          onEmojiClick={(e) => {
            setEmojis([e.emoji, ...emojis]);
            setSelectedEmoji(e.emoji);
            setOpenEmojiDialog(false);
          }}
          skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
        />
      </CustomizedDialogs>
      <AddCustomTagDialog
        open={openNewTagDialog}
        setOpen={setOpenNewTagDialog}
      />
      <Dialog
        open={openRecordScreen}
        setOpen={setOpenRecordScreen}
        fullScreen={!isDesktop}
        sx={{ zIndex: 9999, width: 'full', bgcolor: 'white' }}
      >
        <Stack sx={{ p: 3, width: '100%', height: '100%', minHeight: 500 }}>
          <Stack
            sx={{ width: '100%', mb: 3 }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <IconButton
              onClick={() => {
                setOpenRecordScreen(false);
                setOpen(false);
              }}
              sx={{ ml: -1.5 }}
            >
              <CancelOutlined />
            </IconButton>
            <Button
              variant="outlined"
              onClick={() => setOpenRecordScreen(false)}
              sx={{ borderRadius: 99 }}
              id="step-3"
            >
              Type instead
            </Button>
          </Stack>
          <Typography
            sx={{
              fontSize: 20,
              color:
                description.length > 0 && !loadingSpeechToText && !isRecording
                  ? '#0C3954'
                  : '#859da9',
              fontWeight: 500,
            }}
          >
            {isRecording &&
              !loadingSpeechToText &&
              `Listening${'.'.repeat(dots)}`}
            {description.length > 0 &&
              !loadingSpeechToText &&
              !isRecording &&
              description}
            {!isRecording &&
              !loadingSpeechToText &&
              description.length === 0 &&
              'Tap record and start speaking...'}
          </Typography>
          {loadingSpeechToText && (
            <Stack
              direction={'column'}
              gap={1.5}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                background: 'white',
                zIndex: 999999999,
                // mt: -3,
                // ml: -3,
                top: 0,
                left: 0,
              }}
            >
              <Box
                sx={{
                  width: 150,
                  height: 50,
                  fontSize: 24,
                  color: 'black',
                  fontWeight: 500,
                }}
              >{`Transcribing${'.'.repeat(dots)}`}</Box>
              <Lottie
                animationData={require('../assets/lotties/analyzing.json')}
                style={{
                  height: 160,
                  width: 160,
                }}
                loop={true}
              />
            </Stack>
          )}
          <Box flexGrow={1}></Box>
          <Stack sx={{ position: 'relative' }} alignItems={'center'}>
            {recordStatus === 'play' && (
              <IconButton
                sx={{
                  'bgcolor': '#033248',
                  'top': '50%',
                  'left': '50%',
                  'transform': 'translate(-50%, -50%)',
                  'position': 'absolute',
                  ':hover': {
                    bgcolor: '#033248',
                  },
                  ':focus': {
                    bgcolor: '#033248',
                  },
                  'width': 72,
                  'height': 72,
                }}
                size="large"
                onClick={() => {
                  startRecording();
                  setRecordStatus('pause');
                }}
                id="step-2"
              >
                <Mic sx={{ color: 'white', width: 24, height: 24 }} />
              </IconButton>
            )}
            {recordStatus === 'pause' && (
              <IconButton
                sx={{
                  'bgcolor': '#033248',
                  'top': '50%',
                  'left': '50%',
                  'transform': 'translate(-50%, -50%)',
                  'position': 'absolute',
                  ':hover': {
                    bgcolor: '#033248',
                  },
                  ':focus': {
                    bgcolor: '#033248',
                  },
                  'width': 72,
                  'height': 72,
                }}
                size="large"
                onClick={() => {
                  stopRecording();
                  setRecordStatus('play');
                }}
              >
                <Stop sx={{ color: 'white', width: 24, height: 24 }} />
              </IconButton>
            )}
            <ReactMic
              record={isRecording}
              onStop={onStop}
              onData={onData}
              strokeColor="#88D18D"
              className="soundWaves"
              visualSetting="sinewave" // defaults -> "sinewave".  Other option is "frequencyBars
              mimeType="audio/wav"
            />
          </Stack>
          <Stack
            sx={{ position: 'relative', height: 80, width: '100%' }}
            alignItems={'center'}
            justifyContent={'end'}
          >
            {description.length > 0 && (
              <LoadingButton
                sx={{ borderRadius: 99 }}
                size="large"
                variant="contained"
                onClick={() => analyzeText()}
                loading={analyzingText}
                fullWidth
              >
                Next
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullScreen={!isDesktop}
      >
        <Box sx={{ p: 2, pb: 0 }}>
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {!event ? (
              <Button
                size="small"
                startIcon={<ArrowBackIos />}
                onClick={() => {
                  setOpenRecordScreen(true);
                }}
              >
                Back
              </Button>
            ) : (
              <div></div>
            )}
            <IconButton onClick={() => setOpen(false)}>
              <Close sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Stack>
        </Box>
        <Box sx={{ p: 3, pt: 2 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 600, pt: 1, pb: 2 }}>
            {event ? 'Edit entry' : 'What’s on your mind?'}
          </Typography>
          <Stack direction={'column'} spacing={1}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography>Add Details *</Typography>
              <EditOutlined onClick={() => descriptionRef.current.focus()} />
            </Stack>
            <TextField
              inputRef={descriptionRef}
              multiline
              minRows={1}
              variant="standard"
              label=""
              placeholder="Describe what you’re going through now..."
              sx={{ mt: 1 }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div></div>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography>Edit Time</Typography>
              <CalendarTodayOutlined />
            </Stack>
            <MobileDateTimePicker
              label=""
              ref={dateTimePickerRef}
              value={datetime}
              onChange={(newValue) => setDatetime(newValue)}
              slotProps={{
                textField: {
                  variant: 'standard',
                },
              }}
            />
            <div></div>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography>Add Emoji</Typography>
              <SentimentSatisfiedOutlined />
            </Stack>
            <TextField
              variant="standard"
              label=""
              placeholder="Choose an emoji to show how you’re feeling"
              sx={{ mt: 1 }}
              value={selectedEmoji}
              onChange={(e) => setDescription(e.target.value)}
              onClick={() => setOpenEmojiDialog(true)}
            />
            <div></div>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography>Add Image</Typography>
              <CameraAltOutlined />
            </Stack>
            {imageUrl === null ? (
              <>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => handleFile(e.target.files)}
                />
                <TextField
                  variant="standard"
                  label=""
                  placeholder="Choose an image"
                  sx={{ mt: 1 }}
                  onClick={() => handleFileSelect()}
                />
              </>
            ) : (
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ position: 'relative' }}
              >
                <ImageGroup>
                  <ul className="images">
                    <li>
                      <Image
                        src={imageUrl}
                        alt="nature"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </li>
                  </ul>
                </ImageGroup>
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    setFile(null);
                    setImageUrl(null);
                  }}
                >
                  Remove
                </Button>
              </Stack>
            )}
            <div></div>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography>Select relevant tags *</Typography>
              <SellOutlined />
            </Stack>
            <div></div>
            <Stack
              spacing={0}
              rowGap={1.5}
              direction={'row'}
              sx={{ flexWrap: 'wrap' }}
              justifyContent={'start'}
            >
              {[...getEvents(), ...customEvents].map((event, _index) => (
                <Button
                  variant="contained"
                  onClick={(e) => {
                    onEventClick(event.id);
                    e.target.blur();
                  }}
                  sx={{
                    'bgcolor': selectedEvents.includes(event.id)
                      ? event.color
                      : 'transparent',
                    'border': `2px solid ${event.color}`,
                    'boxShadow': 'none',
                    'color': selectedEvents.includes(event.id)
                      ? event.textColor
                      : 'black',
                    'borderRadius': 5,
                    'mr': 1.5,
                    ':hover': {
                      bgcolor: selectedEvents.includes(event.id)
                        ? event.color
                        : 'transparent',
                      color: selectedEvents.includes(event.id)
                        ? event.textColor
                        : 'black',
                    },
                  }}
                  key={event.id}
                >
                  {event.name}
                </Button>
              ))}
              <Button
                variant="contained"
                onClick={() => setOpenNewTagDialog(true)}
                sx={{
                  'bgcolor': 'transparent',
                  'border': '2px solid #000000',
                  'boxShadow': 'none',
                  'color': 'black',
                  'borderRadius': 5,
                  'mr': 1.5,
                  ':hover': {
                    bgcolor: 'black',
                    color: 'white',
                  },
                }}
              >
                <AddRounded />
              </Button>
            </Stack>
            <div></div>
            <div></div>
            {event ? (
              <Stack direction={'column'} spacing={2}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  onClick={addEvent}
                  loading={loadingAddingEvent}
                  sx={{ borderRadius: 12 }}
                  disabled={
                    description.length === 0 || selectedEvents.length === 0
                    // selectedEmoji === null
                  }
                >
                  Save changes
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  size="large"
                  onClick={deleteEvent}
                  sx={{ borderRadius: 12, bgcolor: 'gray' }}
                  loading={loadingDeletingEvent}
                >
                  Delete event
                </LoadingButton>
              </Stack>
            ) : (
              <LoadingButton
                variant="contained"
                startIcon={<AddRounded />}
                size="large"
                onClick={addEvent}
                loading={loadingAddingEvent}
                sx={{ borderRadius: 12 }}
                disabled={
                  description.length === 0 || selectedEvents.length === 0
                  // selectedEmoji === null
                }
                id="step-4"
              >
                Add to journal
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}

const getContrastColor = (hexColor) => {
  // Remove the '#' symbol if present
  const color = hexColor.replace('#', '');

  // Extract the RGB components
  const red = parseInt(color.substr(0, 2), 16);
  const green = parseInt(color.substr(2, 2), 16);
  const blue = parseInt(color.substr(4, 2), 16);

  // Calculate the contrast using the formula
  const contrast = (red * 299 + green * 587 + blue * 114) / 1000;

  // Determine the appropriate text color based on the contrast
  return contrast >= 128 ? '#000000' : '#FFFFFF';
};

export const STORYCATCH_EVENTS = [
  {
    id: 'hs1zIKDX',
    type: 'pain',
    name: 'Pain',
    textColor: 'black',
    icon: require('../assets/optionsIcons/painIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/painIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearPainIcon.png'),
    color: '#F5CAC3',
  },
  {
    id: 'hhPAPZbT',
    type: 'mood',
    name: 'Mood',
    textColor: 'black',
    icon: require('../assets/optionsIcons/moodIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/moodIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearMoodIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'YerSkutNa',
    type: 'appointments',
    name: 'Appointments',
    textColor: 'black',
    icon: require('../assets/optionsIcons/symptomsIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/symptomsIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearSymptomsIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'jv7AyZREa',
    type: 'triggers',
    name: 'Triggers',
    textColor: 'white',
    icon: require('../assets/optionsIcons/proceduresIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/proceduresIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearProceduresIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'ibkHXKmea',
    type: 'glimmers',
    name: 'Glimmers',
    textColor: 'white',
    icon: require('../assets/optionsIcons/hospitalVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/hospitalizationIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearHospitalIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'hZS4yw4R',
    type: 'myCheckIn',
    name: 'My Check-in',
    textColor: 'white',
    icon: require('../assets/optionsIcons/doctorVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/doctorVisitIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearDoctorIcon.png'),
    color: '#A182C8',
  },
  {
    id: 'oHNP8mRN',
    type: 'medications',
    name: 'Medications',
    textColor: 'white',
    icon: require('../assets/optionsIcons/medicationIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/medIssueIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearMedicationIcon.png'),
    color: '#B8A1D6',
  },
  {
    id: 'Q7dWiUqG',
    type: 'note',
    name: 'Note',
    textColor: 'black',
    icon: require('../assets/optionsIcons/noteIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/noteIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearNoteIcon.png'),
    color: '#A5D1EE',
  },
];

export const EVENTS = [
  {
    id: 'hs1zIKDX',
    type: 'pain',
    name: 'Pain',
    textColor: 'black',
    icon: require('../assets/optionsIcons/painIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/painIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearPainIcon.png'),
    color: '#F5CAC3',
  },
  {
    id: 'hhPAPZbT',
    type: 'mood',
    name: 'Mood',
    textColor: 'black',
    icon: require('../assets/optionsIcons/moodIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/moodIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearMoodIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'YerSkutN',
    type: 'symptoms',
    name: 'Symptoms',
    textColor: 'black',
    icon: require('../assets/optionsIcons/symptomsIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/symptomsIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearSymptomsIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'jv7AyZRE',
    type: 'procedures',
    name: 'Procedures',
    textColor: 'white',
    icon: require('../assets/optionsIcons/proceduresIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/proceduresIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearProceduresIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'ibkHXKme',
    type: 'hospitalVisit',
    name: 'Hospital Visit',
    textColor: 'white',
    icon: require('../assets/optionsIcons/hospitalVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/hospitalizationIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearHospitalIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'hZS4yw4R',
    type: 'doctorVisit',
    name: 'Doctor Visit',
    textColor: 'white',
    icon: require('../assets/optionsIcons/doctorVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/doctorVisitIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearDoctorIcon.png'),
    color: '#A182C8',
  },
  {
    id: 'oHNP8mRN',
    type: 'medications',
    name: 'Medications',
    textColor: 'white',
    icon: require('../assets/optionsIcons/medicationIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/medIssueIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearMedicationIcon.png'),
    color: '#B8A1D6',
  },
  {
    id: 'Q7dWiUqG',
    type: 'note',
    name: 'Note',
    textColor: 'black',
    icon: require('../assets/optionsIcons/noteIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/noteIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearNoteIcon.png'),
    color: '#A5D1EE',
  },
  {
    id: 'YerSkutNa',
    type: 'appointments',
    name: 'Appointments',
    textColor: 'black',
    icon: require('../assets/optionsIcons/symptomsIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/symptomsIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearSymptomsIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'jv7AyZREa',
    type: 'triggers',
    name: 'Triggers',
    textColor: 'white',
    icon: require('../assets/optionsIcons/proceduresIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/proceduresIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearProceduresIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'ibkHXKmea',
    type: 'glimmers',
    name: 'Glimmers',
    textColor: 'white',
    icon: require('../assets/optionsIcons/hospitalVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/hospitalizationIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearHospitalIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'hZS4yw4Ra',
    type: 'myCheckIn',
    name: 'My Check-in',
    textColor: 'white',
    icon: require('../assets/optionsIcons/doctorVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/doctorVisitIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearDoctorIcon.png'),
    color: '#A182C8',
  },
];

export const ASSESSMENTS_IDS = ['C7NnGZyA', 'FMkEt9ok'];

export const ASSESSMENTS = [
  {
    id: 'C7NnGZyA',
    type: 'emotionalHealth',
    name: 'Emotional Health',
    icon: require('../assets/optionsIcons/symptomsIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/symptomsIcon.png'),
    color: '#F4FF72',
    totalScore: 27,
  },
  {
    id: 'FMkEt9ok',
    type: 'fatigue',
    name: 'Fatigue',
    icon: require('../assets/optionsIcons/medIssueIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/medIssueIcon.png'),
    color: '#CEF5E3',
    totalScore: 52,
  },
];
