import { Box, Stack } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { usePersistedJsonState } from '../utils/usePersistedState';
import { AuthContext } from './Auth';
import { EventsContext } from './EventsContext';

export const WalkthroughContext = createContext();

const coloredTooltipStyle = {
  options: {
    arrowColor: '#0C3954',
    backgroundColor: '#0C3954',
    beaconSize: 36,
    borderRadius: 16,
    width: 320,
  },
  tooltip: { borderRadius: 16 },
  buttonSkip: {
    color: '#FDDD2E',
  },
  buttonNext: {
    'color': '#fff',
    'backgroundColor': '#69B3E3',
    'borderRadius': '20px',
    'border': 'none',
    'paddingLeft': 16,
    'paddingRight': 16,
    'paddingTop': 8,
    'paddingBottom': 8,
    '&:focus': {
      border: 'none',
    },
  },
};

function WalkthroughContextProvider(props) {
  const {
    openRecordScreen,
    setOpenRecordScreen,
    openCongratsDialog,
    setOpenCongratsDialog,
    setCongratsDialogType,
    setEventDialogIsOpen,
    openEventDialog,
    eventDialogIsOpen,
  } = React.useContext(EventsContext);
  const { isAuthenticated } = React.useContext(AuthContext);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);
  const [walkthroughEverStarted, setWalkthroughEverStarted] =
    usePersistedJsonState('walkthroughEverStarted', false);

  useEffect(() => {
    if (!run && !walkthroughEverStarted && isAuthenticated) {
      startTour();
    }
  }, [walkthroughEverStarted, isAuthenticated, run]);

  const startTour = () => {
    setRun(true);
    setStepIndex(0);
    if (!walkthroughEverStarted) {
      setWalkthroughEverStarted(true);
    }
  };

  const steps = [
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ width: '100%', textAlign: 'center', fontSize: 16 }}
        >
          <Box sx={{ fontSize: 18, fontWeight: 600 }}>
            Learn how to use Health Story
          </Box>
          <Box>
            Add the story of your health here to easily access and share it with
            your family, doctors, and more.
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'No thanks',
        next: 'Take a tour',
      },
      placement: 'center',
      target: 'body',
      hideCloseButton: true,
      disableBeacon: true,
      disableOverlayClose: true,
      showProgress: false,
      styles: {
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          beaconSize: 36,
          primaryColor: 'blue',
          borderRadius: 16,
          width: 320,
        },
        tooltip: { borderRadius: 16 },
        buttonSkip: {
          color: '#69B3E3',
        },
        buttonNext: {
          'color': '#fff',
          'backgroundColor': '#0C3954',
          'borderRadius': '20px',
          'border': 'none',
          'paddingLeft': 16,
          'paddingRight': 16,
          'paddingTop': 8,
          'paddingBottom': 8,
          '&:focus': {
            border: 'none',
          },
        },
      },
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            <b>Tap here</b> on the plus button to start a journal entry.
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'bottom',
      target: '#step-1',
      disableBeacon: true,
      disableOverlayClose: true,
      hideBackButton: true,
      hideCloseButton: true,
      showProgress: false,
      styles: coloredTooltipStyle,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            <b>Tap</b> the microphone button to journal by just speaking!
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'top',
      target: '#step-2',
      hideCloseButton: true,
      hideBackButton: true,
      styles: coloredTooltipStyle,
      showProgress: false,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            Feel free to <b>type instead</b> if you find that easier.
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'bottom',
      target: '#step-3',
      disableOverlayClose: true,
      hideCloseButton: true,
      hideBackButton: true,
      styles: coloredTooltipStyle,
      showProgress: false,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            You can also <b>add emojis, images, and tags</b> to help you better
            describe and organize your journal.
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'top',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      target: '#step-4',
      hideBackButton: true,
      styles: coloredTooltipStyle,
      showProgress: false,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            The more entries you add the <b>higher level</b> you’ll reach!
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'top',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      target: '#step-5',
      hideBackButton: true,
      styles: coloredTooltipStyle,
      showProgress: false,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            Your journal entries will be here on your homepage for you to view
            in the future
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'top',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      target: '#step-6',
      hideBackButton: true,
      styles: coloredTooltipStyle,
      data: {
        next: '/dashboard/surveys',
      },
      showProgress: false,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            <b>Tap here to take surveys</b> that can help you and your doctor{' '}
            <b>better understand</b> how your condition is affecting you
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'top',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      target: '#step-7',
      hideBackButton: true,
      styles: coloredTooltipStyle,
      data: {
        next: '/dashboard/surveys',
      },
      showProgress: false,
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            color: 'white',
          }}
        >
          <Box>
            <b>Tap here</b> to see analytics and AI generated reports about
            entries in your health story{' '}
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Skip',
        next: 'Next',
      },
      placement: 'top',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      target: '#step-8',
      hideBackButton: true,
      styles: coloredTooltipStyle,
      showProgress: false,
      data: {
        next: '/dashboard/analytics',
      },
    },
    {
      content: (
        <Stack
          direction={'column'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ width: '100%', textAlign: 'center', fontSize: 16 }}
        >
          <Box sx={{ fontSize: 18, fontWeight: 600 }}>Get Started</Box>
          <Box>
            Now that you understand the basics, try adding your first entry to
            your health story now
          </Box>
        </Stack>
      ),
      locale: {
        skip: 'Try it later',
        next: 'Try it now!',
        last: 'Try it now!',
      },
      placement: 'center',
      hideBackButton: true,
      hideCloseButton: true,
      showProgress: false,
      target: 'body',
      styles: {
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          beaconSize: 36,
          primaryColor: 'blue',
          borderRadius: 16,
          width: 320,
        },
        tooltip: { borderRadius: 16 },
        buttonSkip: {
          color: '#69B3E3',
        },
        buttonNext: {
          'color': '#fff',
          'backgroundColor': '#0C3954',
          'borderRadius': '20px',
          'border': 'none',
          'paddingLeft': 16,
          'paddingRight': 16,
          'paddingTop': 8,
          'paddingBottom': 8,
          '&:focus': {
            border: 'none',
          },
        },
      },
    },
  ];

  useEffect(() => {
    console.log('openCongratsDialog', openCongratsDialog);
  }, [openCongratsDialog]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    // setRun(false);
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (nextStepIndex === 1) {
        setStepIndex(nextStepIndex);
      } else if (nextStepIndex === 2) {
        setRun(false);
        openEventDialog();
        setTimeout(() => {
          setRun(true);
          setStepIndex(nextStepIndex);
        }, 400);
      } else if (nextStepIndex === 3) {
        setStepIndex(nextStepIndex);
      } else if (openRecordScreen && nextStepIndex === 4) {
        setRun(false);
        setOpenRecordScreen(false);
        setTimeout(() => {
          setRun(true);
          setStepIndex(nextStepIndex);
        }, 400);
      } else if (
        eventDialogIsOpen &&
        !openCongratsDialog &&
        nextStepIndex === 5
      ) {
        setRun(false);
        setOpenRecordScreen(false);
        setEventDialogIsOpen(false);
        setOpenCongratsDialog(true);
        setCongratsDialogType('25Entries');
        setTimeout(() => {
          setRun(true);
          setStepIndex(nextStepIndex);
        }, 400);
      } else if (openCongratsDialog && nextStepIndex === 6) {
        setRun(false);
        setOpenCongratsDialog(false);
        setEventDialogIsOpen(false);
        setTimeout(() => {
          setRun(true);
          setStepIndex(nextStepIndex);
        }, 400);
      } else if (nextStepIndex === 7) {
        setRun(true);
        setStepIndex(nextStepIndex);
      } else if (nextStepIndex === 8) {
        setRun(true);
        setStepIndex(nextStepIndex);
      } else if (nextStepIndex === 9) {
        setRun(true);
        setStepIndex(nextStepIndex);
      } else {
        console.log('Unhandled action 1', data);
        if (action === ACTIONS.NEXT) {
          setStepIndex(nextStepIndex);
          setRun(false);
          setEventDialogIsOpen(true);
        }
      }
    } else {
      console.log('Unhandled action 2', data);
    }
  };

  return (
    <WalkthroughContext.Provider
      value={{ run, stepIndex, steps, startTour, handleJoyrideCallback }}
    >
      <div>{props.children}</div>
    </WalkthroughContext.Provider>
  );
}

export default WalkthroughContextProvider;
