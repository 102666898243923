import { Card, Stack, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import React from 'react';

const CARD = {
  p: 3,
  borderRadius: 3,
  minHeight: 288,
};

const STACK = {
  width: '100%',
};

const TITLE = {
  fontSize: 16,
  fontWeight: 600,
  color: '#0B3954',
  textAlign: 'start',
};

const KEYVALUE = {
  fontSize: 16,
  color: 'black',
  textAlign: 'start',
};

const AnalyticsCard = ({ title, items }) => {
  return (
    <Card sx={CARD}>
      <Stack spacing={3} sx={STACK}>
        <Typography sx={TITLE}>{title}</Typography>
        {items.map((item, index) => (
          <AnalyticsLine
            key={index}
            title={item.title}
            color={item.color}
            value={item.value}
            maxValue={item.maxValue}
          />
        ))}
      </Stack>
    </Card>
  );
};

export default AnalyticsCard;

const AnalyticsLine = ({ title, color, value, maxValue }) => {
  return (
    <Stack spacing={1} sx={STACK}>
      <Stack
        sx={STACK}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={KEYVALUE}>{title}</Typography>
        <Typography sx={KEYVALUE}>{value}</Typography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={(value / maxValue) * 100}
        sx={{
          height: 16,
          borderRadius: 8,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#D8ECF9',
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: color,
          },
        }}
      />
    </Stack>
  );
};
