import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { EventsContext } from '../contexts/EventsContext';

const Badges = ({ level }) => {
  const navigate = useNavigate();

  const { events } = React.useContext(EventsContext);

  const levels = [
    'starter',
    'star',
    'superStar',
    'hero',
    'superHero',
    'legend',
  ];

  const entriesLeftBeforeNextLevel = (level, events) => {
    switch (level) {
      case 'starter':
        return 10 - events.length;
      case 'star':
        return 25 - events.length;
      case 'superStar':
        return 50 - events.length;
      case 'hero':
        return 100 - events.length;
      case 'superHero':
        return 500 - events.length;
      case 'legend':
        return 0;
      default:
        return 10 - events.length;
    }
  };

  return (
    <Stack
      direction={'column'}
      spacing={2}
      alignItems={'start'}
      sx={{
        mt: 3,
        bgcolor: 'white',
        borderRadius: 3,
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        ml: 2,
        mr: 2,
        p: 3,
      }}
      onClick={() => navigate('/dashboard/progress')}
    >
      <Stack
        direction={'row'}
        justifyContent={'start'}
        alignItems={'center'}
        spacing={2}
        sx={{ width: '100%' }}
      >
        <img
          src={require('../assets/icons/levelIcon.png')}
          width={32}
          height={32}
          alt={'level'}
        />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 18,
            flexGrow: 1,
            textAlign: 'start',
          }}
        >
          Progress
        </Typography>
        <KeyboardArrowRight />
      </Stack>
      <Typography sx={{ fontWeight: 600 }}>Starter</Typography>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={1}
        sx={{ width: '100%', p: 0 }}
      >
        {levels.map((i, index) => (
          <Box
            key={index}
            sx={{
              bgcolor: levels.indexOf(level) >= index ? '#86E7B8' : '#86E7B830',
              width: 'auto',
              flex: 1,
              height: 10,
              borderRadius: 99,
            }}
          ></Box>
        ))}
      </Stack>
      <Typography sx={{ fontSize: 12 }}>
        {entriesLeftBeforeNextLevel(levels, events) * -1} more entries to reach
        the next level
      </Typography>
    </Stack>
  );
};

export default Badges;
