import moment from 'moment-timezone';
import React, { createContext, useContext, useEffect, useState } from 'react';
import AddOrEditEventDialog, {
  ASSESSMENTS_IDS,
} from '../components/AddOrEditEventDialog';
import FilterJournalEventsDialog from '../components/FilterJournalEventsDialog';
import CongratsDialogs from '../components/dialogs/CongratsDialogs';
import { getEvents } from '../services/events';
import { AuthContext } from './Auth';
import { ProfileContext } from './Profile';

// Create a context
export const EventsContext = createContext();

const EventsProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { setLevel, role, currentProfile } = useContext(ProfileContext);
  const [events, setEvents] = React.useState([]);
  const [selectedEvents, setSelectedEvents] = React.useState([]);
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [eventDialogIsOpen, setEventDialogIsOpen] = useState(false);

  const [openCongratsDialog, setOpenCongratsDialog] = React.useState(false);
  const [congratsDialogType, setCongratsDialogType] = React.useState(false);

  const [openRecordScreen, setOpenRecordScreen] = React.useState(false);

  const resetContext = () => {
    setEvents([]);
    setSelectedEvents([]);
    setOpenFilterDialog(false);
    setEventDialogIsOpen(false);
    setOpenRecordScreen(false);
    setOpenCongratsDialog(false);
    setCongratsDialogType(false);
  };

  useEffect(() => {
    if (openRecordScreen) {
      setEventDialogIsOpen(true);
    }
  }, [openRecordScreen]);

  const openEventDialog = () => {
    setOpenRecordScreen(true);
  };

  const fetchEvents = async (id) => {
    try {
      const fetchedData = await getEvents(id);
      console.log('fetchedData', fetchedData);
      if (!fetchedData) return;
      setEvents(
        fetchedData?.filter(
          (e) =>
            e.data().format === 'new' ||
            ASSESSMENTS_IDS.includes(e.data()?.formId),
        ) || [],
      );
      return;
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (isAuthenticated && currentProfile) {
      fetchEvents(currentProfile.id);
    }
  }, [isAuthenticated, currentProfile]);

  useEffect(() => {
    if (events.length < 10) setLevel('starter');
    if (events.length >= 10 && events.length < 25) setLevel('star');
    if (events.length >= 25 && events.length < 50) setLevel('superStar');
    if (events.length >= 50 && events.length < 100) setLevel('hero');
    if (events.length >= 100 && events.length < 499) setLevel('superHero');
    if (events.length >= 500) setLevel('legend');
  }, [events]);

  const postJournal = () => {
    if (events.length + 1 === 1) {
      setCongratsDialogType('firstEntry');
      setOpenCongratsDialog(true);
      return;
    }
    if (events.length + 1 === 10) {
      setCongratsDialogType('10Entries');
      setOpenCongratsDialog(true);
      return;
    }
    if (events.length + 1 === 25) {
      setCongratsDialogType('25Entries');
      setOpenCongratsDialog(true);
      return;
    }
    if (events.length + 1 === 50) {
      setCongratsDialogType('50Entries');
      setOpenCongratsDialog(true);
      return;
    }
    if (events.length + 1 === 100) {
      setCongratsDialogType('100Entries');
      setOpenCongratsDialog(true);
      return;
    }
    if (events.length + 1 === 500) {
      setCongratsDialogType('500Entries');
      setOpenCongratsDialog(true);
      return;
    }
    if (isEventInPreviousDays(3, events)) {
      setCongratsDialogType('3Entries');
      setOpenCongratsDialog(true);
      return;
    }
    if (isEventInPreviousDays(2, events)) {
      setCongratsDialogType('2Entries');
      setOpenCongratsDialog(true);
      return;
    }
    setCongratsDialogType('entryComplited');
    setOpenCongratsDialog(true);
    return;
  };

  const isEventInPreviousDays = (days, allEvents) => {
    const currentEventTime = moment();
    const dayBeforeRangeStart = moment(currentEventTime)
      .subtract(days, 'days')
      .startOf('day');
    const dayBeforeRangeEnd = moment(currentEventTime)
      .subtract(days, 'days')
      .endOf('day');

    return allEvents.some((event) => {
      const eventTime = moment(event.data().submittedAt.seconds * 1000);
      return eventTime.isBetween(dayBeforeRangeStart, dayBeforeRangeEnd);
    });
  };

  return (
    <EventsContext.Provider
      value={{
        events,
        fetchEvents,
        setEvents,
        selectedEvents,
        setSelectedEvents,
        setOpenFilterDialog,
        setEventDialogIsOpen,
        eventDialogIsOpen,
        openEventDialog,
        openRecordScreen,
        setOpenRecordScreen,
        openCongratsDialog,
        setOpenCongratsDialog,
        setCongratsDialogType,
        resetContext,
      }}
    >
      <CongratsDialogs
        open={openCongratsDialog}
        setOpen={setOpenCongratsDialog}
        type={congratsDialogType}
      />
      {(eventDialogIsOpen === true || openRecordScreen === true) && (
        <AddOrEditEventDialog
          open={eventDialogIsOpen}
          setOpen={setEventDialogIsOpen}
          openRecordScreen={openRecordScreen}
          setOpenRecordScreen={setOpenRecordScreen}
          postJournal={postJournal}
        />
      )}
      <FilterJournalEventsDialog
        open={openFilterDialog}
        setOpen={setOpenFilterDialog}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
      />
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;
