import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import firebase from '../Firebase';
import OnboardingLayoutOld from '../components/OnboardingLayoutOld';
import { AuthContext } from '../contexts/Auth';
import { track } from '../utils/analytics';
import { emailIsValid, passwordIsValid } from '../utils/validator';

const HAVE_ACCOUNT = {
  marginTop: 24,
  marginBottom: 8,
};

const FORM = {
  width: '100%',
};

const TERMS = {
  marginTop: 16,
  paddingLeft: 32,
  paddingRight: 32,
  fontSize: 14,
};

const ERROR_MESSAGE = {
  color: 'red',
  fontSize: 14,
  textAlign: 'start',
};

const Login = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let isValid = true;
    if (!emailIsValid(email)) {
      setErrorEmail('Email is not valid');
      isValid = false;
    }
    if (!passwordIsValid(password)) {
      setErrorPassword('Password is not valid');
      isValid = false;
    }
    return isValid;
  };

  const onLogin = async (provider) => {
    setError(null);
    if (provider === 'facebook') {
      loginWithFacebook();
    } else if (provider === 'google') {
      loginWithGoogle();
    } else {
      if (checkValidation()) {
        loginWithEmailPaswword();
      }
    }
  };

  const loginWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithPopup(provider);
    track('login_with_facebook_button_click');
  };

  const loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithPopup(provider);
    track('login_with_google_button_click');
  };

  const loginWithEmailPaswword = async () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // var user = userCredential.user;
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
    track('login_with_email_and_password_button_click');
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/home');
    }
  }, [isAuthenticated, navigate]);

  return (
    <OnboardingLayoutOld>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Welcome Back
        </Typography>
        <div></div>
        {/* <LoadingButton
          onClick={() => onLogin('google')}
          startIcon={
            <img
              alt="logo"
              src={require('../assets/icons/google.png')}
              height={24}
              width={24}
              style={{ marginInlineEnd: 8 }}
            />
          }
          variant="white"
          size="large"
        >
          Continue with Google
        </LoadingButton>
        <div></div> */}

        {/* <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          alignItems={'center'}
          spacing={4}
        >
          <div
            style={{
              height: 1,
              width: 'auto',
              flex: 1,
              backgroundColor: '#E0E0E0',
            }}
          ></div>
          <Typography sx={{ fontSize: 16, color: 'gray.700' }}>Or</Typography>
          <div
            style={{
              height: 1,
              width: 'auto',
              flex: 1,
              backgroundColor: '#E0E0E0',
            }}
          ></div>
        </Stack> */}
        <div></div>
        <TextField
          label="Email"
          fullWidth
          type={'email'}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorEmail(null);
          }}
          error={errorEmail !== null}
          helperText={errorEmail}
        />
        <TextField
          label="Password"
          fullWidth
          type={'password'}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorPassword(null);
          }}
          error={errorPassword !== null}
          helperText={errorPassword}
        />
        <Stack
          direction={'row'}
          justifyContent={'end'}
          alignItems={'center'}
          spacing={1}
        >
          <Button onClick={() => navigate('/reset-password')} size="small">
            Forgot password?
          </Button>
        </Stack>
        <LoadingButton
          loading={loading}
          onClick={() => onLogin('email')}
          variant="contained"
          size="large"
        >
          Login
        </LoadingButton>
        {error && <Typography sx={ERROR_MESSAGE}>{error}</Typography>}
        <Stack
          style={HAVE_ACCOUNT}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <Typography sx={{ fontSize: 16 }}>
            Don&apos;t have an account?
          </Typography>
          <Button onClick={() => navigate('/signup')} size="medium">
            Signup
          </Button>
        </Stack>
        <p style={TERMS}>
          By signing in I accept the{' '}
          <a target="_blank" href="https://knowrare.com/terms" rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          and the{' '}
          <a
            target="_blank"
            href="https://knowrare.com/privacy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </Stack>
    </OnboardingLayoutOld>
  );
};

export default Login;
